@charset "UTF-8";

@import url(//fonts.googleapis.com/earlyaccess/notosansjapanese.css);

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Medium");
  font-weight: normal;
}

@font-face {
  font-family: "Yu Gothic";
  src: local("Yu Gothic Bold");
  font-weight: bold;
}

/* --------------------
 body
-------------------- */

html {
  font-size: 62.5%;
  /* 10px基準 */
}

body {
  color: #4d4d4d;
  line-height: 1.7;
  font-size: 14px;
  font-size: 1.4rem;
  background-color: #e0e0e0;
  font-family: "Noto Sans Japanese", "Yu Gothic", YuGothic, sans-serif;
}

body * {
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #4d4d4d;
  text-decoration: underline;
}

a:hover {
  color: #f00;
  text-decoration: underline;
}

a[href^="tel:"] {
  pointer-events: none;
  text-decoration: none !important;
}

img.centered {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

img.alignright {
  padding: 4px;
  margin: 0 0 2px 7px;
  display: inline;
}

img.alignleft {
  padding: 4px;
  margin: 0 7px 2px 0;
  display: inline;
}

.alignright {
  float: right;
}

.alignleft {
  float: left;
}

.wp-caption {
  max-width: 100%;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

em {
  font-style: italic;
}

/* Advanced Responsive Video Embedder プラグイン用 */

.aligncenter {
  margin: 0 auto !important;
}

#wrapper {
  margin: 0 auto;
  max-width: 1680px;
  background-color: #fff;
}

/* --------------------
 header
-------------------- */

#header {
  height: 90px;
  background: #114c11 url(../img/com/header/bg-header.jpg) no-repeat center top;
  position: relative;
  z-index: 3;
}

#header .box-top {
  margin: 0 auto;
  max-width: 1240px;
  height: 90px;
  position: relative;
}

#header .box-top #logo {
  width: 370px;
  background: url(../img/com/header/sp-logo.png) no-repeat 10000px 0;
  position: absolute;
  top: 14px;
  left: 0;
}

#header .box-top #h-tel {
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  position: absolute;
  top: 14px;
  left: 386px;
}

#header .box-top #h-tel dt,
#header .box-top #h-tel dd {
  line-height: 1.2;
}

#header .box-top #h-tel .tel {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 100;
}

#header #sp-btn-nav {
  display: none;
}

#header .box-top .m-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
  right: 116px;
}

#header .box-top .m-nav li {
  margin: 0;
  padding: 0;
  border-right: 1px #808080 solid;
  width: 120px;
  height: 90px;
  display: table;
  float: left;
}

#header .box-top .m-nav li:last-child {
  border-right: none;
}

#header .box-top .m-nav li.sp {
  display: none;
}

#header .box-top .m-nav li a {
  display: table-cell;
  color: #f2f7d7;
  background-color: #000;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  position: relative;
}

#header .box-top .m-nav li a:hover {
  opacity: 0.6;
}

#header .box-top .m-nav li.new a::after {
  content: " ";
  width: 34px;
  height: 14px;
  display: block;
  background: url(../img/com/header/icon-new.gif) no-repeat left top;
  background-size: cover;
  position: absolute;
  top: 8px;
  right: 6px;
}

#header .box-top .s-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 14px;
  right: 494px;
}

#header .box-top .s-nav li {
  margin: 0;
  padding: 0 0 3px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
}

#header .box-top .s-nav li:last-child {
  padding: 0;
}

#header .box-top .s-nav li.sp {
  display: none;
}

#header .box-top .s-nav li a {
  color: #fff;
  text-decoration: none;
}

#header .box-top .s-nav li a:hover {
  opacity: 0.6;
}

#header .box-top .o-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  top: 0;
  right: 0;
}

#header .box-top .o-nav li {
  margin: 0;
  padding: 0;
  background: url(../img/com/header/sp-btn-yoyaku.png) no-repeat 10000px 0;
}

#main {
  position: relative;
}

.pankuzu {
  padding: 5px 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.4);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

#p-btm {
  display: none;
}

.pankuzu .inner {
  margin: 0 auto;
  max-width: 1240px;
  color: #e5e5e5;
  font-size: 12px;
  font-size: 1.2rem;
}

.pankuzu a {
  color: #e5e5e5;
  text-decoration: underline;
}

/* --------------------
 footer
-------------------- */

#footer {
  padding: 60px 0 50px;
  background-color: #000;
}

#footer > .inner {
  margin: 0 auto;
  max-width: 1240px;
}

#f-address {
  color: #b3b3b3;
  float: left;
  width: 380px;
}

#f-address dt {
  margin-bottom: 16px;
}

#f-address .yoyaku {
  margin-top: 20px;
}

#f-address .yoyaku .box-l {
  padding: 8px 0;
  width: 126px;
  border: 1px #666 solid;
  float: left;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  text-align: center;
}

#f-address .yoyaku .box-r {
  margin-left: 136px;
  color: #fff;
  line-height: 1.2;
}

#f-address .yoyaku .box-r a {
  color: #fff;
}

#f-address .yoyaku .box-r .tel {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 100;
  line-height: 1;
}

#footer .sp-yoyaku,
#footer .sp-sns {
  display: none;
}

#footer .f-box-1 {
  float: right;
  width: 660px;
}

#footer .f-box-1 .box {
  letter-spacing: -0.4em;
  width: 474px;
  float: left;
}

#footer .f-box-1 .box ul {
  margin: 0;
  padding: 0 60px 0 0;
  list-style: none;
  letter-spacing: normal;
  display: inline-block;
  vertical-align: top;
}

#footer .f-box-1 .box ul:nth-child(3n) {
  padding: 0;
}

#footer .f-box-1 .box ul li {
  margin: 0;
  padding: 0 0 20px;
  color: #fff;
}

#footer .f-box-1 .box ul li a {
  color: #fff;
  text-decoration: none;
}

#footer .f-box-1 .box ul li a:hover {
  opacity: 0.6;
}

#footer .f-box-1 .box ul li i {
  margin-right: 8px;
}

#footer .f-box-1 .box ul.sns {
  padding-top: 10px;
}

#footer .f-box-1 .btn-yoyaku {
  margin: 0;
  padding: 0;
  list-style: none;
  float: right;
}

#footer .f-box-1 .btn-yoyaku li {
  margin: 0;
  padding: 0;
}

#page-top {
  width: 35px;
  height: 35px;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 10;
}

#copyright {
  padding: 16px 0;
  border-top: 1px #363f35 solid;
  color: #fff;
  background-color: #040f03;
  font-size: 12px;
  font-size: 1.2rem;
  text-align: center;
}

#copyright a {
  color: #fff;
  text-decoration: none;
}

#copyright a:hover {
  opacity: 0.6;
}

@media screen and (max-width: 1024px) {
  /* --------------------
  	body
  -------------------- */

  a[href^="tel:"] {
    pointer-events: auto;
    text-decoration: underline !important;
  }

  select,
  input,
  textarea {
    font-size: 16px;
  }

  #wrapper {
    max-width: none;
  }

  /* --------------------
  	header
  -------------------- */

  #header {
    height: 56px;
    background: #114c11 none;
    position: relative;
    z-index: 100;
  }

  #header .box-top {
    max-width: none;
    height: 56px;
  }

  #header .box-top #logo {
    width: 216px;
    height: 34px;
    background-position: 0 0;
    background-size: cover;
    position: absolute;
    top: 14px;
    left: 14px;
  }

  #header .box-top #logo a {
    width: 216px;
    height: 34px;
    display: block;
  }

  #header .box-top #logo img {
    visibility: hidden;
    width: 216px;
    height: 34px;
  }

  #header .box-top #h-tel {
    display: none;
  }

  #header #sp-btn-nav {
    display: block;
    width: 58px;
    height: 56px;
    position: absolute;
    top: 0;
    right: 58px;
    cursor: pointer;
  }

  #header #sp-btn-nav.active {
    opacity: 0.6;
  }

  #header .box-top nav {
    display: none;
    position: absolute;
    top: 56px;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  #header .box-top nav.active {
    display: block;
  }

  #header .box-top .m-nav {
    position: static;
  }

  #header .box-top .m-nav li {
    border-right: none;
    border-bottom: 1px #808080 solid;
    width: 100%;
    height: auto;
    display: block;
    float: none;
  }

  #header .box-top .m-nav li:last-child {
    border-right: none;
  }

  #header .box-top .m-nav li.sp {
    display: block;
  }

  #header .box-top .m-nav li a {
    padding: 8px 15px;
    display: block;
    text-align: left;
  }

  #header .box-top .m-nav li a:hover {
    opacity: 1;
  }

  #header .box-top .m-nav li.new a::after {
    display: inline-block;
    position: relative;
    top: 2px;
    right: auto;
    margin-left: 4px;
  }

  #header .box-top .s-nav {
    position: static;
    background-color: #000;
  }

  #header .box-top .s-nav li {
    padding: 0;
    font-size: 14px;
    font-size: 1.4rem;
    border-bottom: 1px #808080 solid;
  }

  #header .box-top .s-nav li:last-child {
    padding: 0;
  }

  #header .box-top .s-nav li.sp {
    display: block;
  }

  #header .box-top .s-nav li a {
    padding: 8px 15px;
    display: block;
    color: #f2f7d7;
    background-color: #000;
  }

  #header .box-top .s-nav li a:hover {
    opacity: 1;
  }

  #header .box-top .o-nav {
    width: 58px;
    height: 65px;
  }

  #header .box-top .o-nav li {
    width: 58px;
    height: 65px;
    background-position: 0 0;
    background-size: cover;
  }

  #header .box-top .o-nav li a {
    display: block;
    width: 58px;
    height: 65px;
  }

  #header .box-top .o-nav img {
    width: 58px;
    height: 65px;
    visibility: hidden;
  }

  #main {
    position: relative;
  }

  #p-top {
    display: none;
  }

  #p-btm {
    display: block;
  }

  .pankuzu {
    padding: 8px 12px;
    position: static;
    background-color: #e4e5e4;
  }

  .pankuzu .inner {
    max-width: none;
    color: #59595b;
  }

  .pankuzu .inner a {
    color: #59595b;
  }

  /* --------------------
  	footer
  -------------------- */

  #footer {
    padding: 60px 15px 50px;
  }

  #footer .f-box-1 {
    float: right;
    width: 560px;
  }

  #footer .f-box-1 .box {
    letter-spacing: -0.4em;
    width: 400px;
    float: left;
  }

  #footer .f-box-1 .box ul {
    margin: 0;
    padding: 0 20px 0 0;
    list-style: none;
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
  }

  #footer .f-box-1 .box ul:nth-child(3n) {
    padding: 0;
  }

  #footer .f-box-1 .box ul li {
    margin: 0;
    padding: 0 0 20px;
    color: #fff;
  }

  #footer .f-box-1 .box ul li a {
    color: #fff;
    text-decoration: none;
  }

  #footer .f-box-1 .box ul li a:hover {
    opacity: 0.6;
  }

  #footer .f-box-1 .box ul li i {
    margin-right: 8px;
  }

  #footer .f-box-1 .box ul.sns {
    padding-top: 10px;
    display: block;
  }

  #footer .f-box-1 .btn-yoyaku {
    float: right;
  }

  #copyright {
    padding: 16px 0;
    border-top: 1px #363f35 solid;
    color: #fff;
    background-color: #040f03;
    font-size: 12px;
    font-size: 1.2rem;
    text-align: center;
  }

  #copyright a {
    color: #fff;
    text-decoration: none;
  }

  #copyright a:hover {
    opacity: 0.6;
  }
}

@media screen and (max-width: 768px) {
  .sp-br {
    display: block;
  }

  .spbr {
    display: block;
  }

  #header .box-top .o-nav {
    position: fixed;
  }

  /* --------------------
  	footer
  -------------------- */

  #footer {
    padding: 26px 0 18px;
  }

  #footer > .inner {
    padding: 0 10px;
    max-width: 320px;
  }

  #f-address {
    float: none;
    width: 100%;
  }

  #f-address dt {
    margin-bottom: 10px;
    width: 274px;
  }

  #f-address dd {
    font-size: 12px;
    font-size: 1.2rem;
  }

  #f-address .yoyaku {
    display: none;
  }

  #footer .sp-yoyaku {
    display: block;
    margin-top: 10px;
  }

  #footer .sp-yoyaku a {
    padding: 14px;
    display: block;
    border: 2px #b3b3b3 solid;
    color: #fff;
    text-align: center;
    text-decoration: none !important;
  }

  #footer .sp-sns {
    display: block;
    margin-top: 10px;
  }

  #footer .sp-sns a {
    color: #fff;
    text-decoration: none;
  }

  #footer .sp-sns i {
    margin-right: 6px;
  }

  #footer .f-box-1 {
    display: none;
  }

  #page-top {
    right: 15px;
    bottom: 15px;
  }

  #copyright {
    padding: 8px 0;
    font-size: 10px;
    font-size: 1rem;
  }

  #copyright a:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 340px) {
  /* --------------------
  	header
  -------------------- */

  #header .box-top #logo {
    width: 180px;
    height: 28px;
    background-position: 0 0;
    background-size: cover;
    position: absolute;
    top: 17px;
    left: 14px;
  }

  #header .box-top #logo a {
    width: 180px;
    height: 28px;
    display: block;
  }

  #header .box-top #logo img {
    visibility: hidden;
    width: 180px;
    height: 28px;
  }
}

@media print {
  /* --------------------
  	body
  -------------------- */

  a[href^="tel:"] {
    pointer-events: auto;
    text-decoration: underline !important;
  }

  select,
  input,
  textarea {
    font-size: 16px;
  }

  #wrapper {
    max-width: none;
  }

  /* --------------------
  	header
  -------------------- */

  #header {
    height: 56px;
    background: #114c11 none;
    position: relative;
    z-index: 100;
  }

  #header .box-top {
    max-width: none;
    height: 56px;
  }

  #header .box-top #logo {
    width: 216px;
    height: 34px;
    background-position: 0 0;
    background-size: cover;
    position: absolute;
    top: 14px;
    left: 14px;
  }

  #header .box-top #logo a {
    width: 216px;
    height: 34px;
    display: block;
  }

  #header .box-top #logo img {
    visibility: hidden;
    width: 216px;
    height: 34px;
  }

  #header .box-top #h-tel {
    display: none;
  }

  #header #sp-btn-nav {
    display: block;
    width: 58px;
    height: 56px;
    position: absolute;
    top: 0;
    right: 58px;
    cursor: pointer;
  }

  #header #sp-btn-nav.active {
    opacity: 0.6;
  }

  #header .box-top nav {
    display: none;
    position: absolute;
    top: 56px;
    right: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
  }

  #header .box-top nav.active {
    display: block;
  }

  #header .box-top .m-nav {
    position: static;
  }

  #header .box-top .m-nav li {
    border-right: none;
    border-bottom: 1px #808080 solid;
    width: 100%;
    height: auto;
    display: block;
    float: none;
  }

  #header .box-top .m-nav li:last-child {
    border-right: none;
  }

  #header .box-top .m-nav li.sp {
    display: block;
  }

  #header .box-top .m-nav li a {
    padding: 8px 15px;
    display: block;
    text-align: left;
  }

  #header .box-top .m-nav li a:hover {
    opacity: 1;
  }

  #header .box-top .m-nav li.new a::after {
    display: inline-block;
    position: relative;
    top: 2px;
    right: auto;
    margin-left: 4px;
  }

  #header .box-top .s-nav {
    position: static;
    background-color: #000;
  }

  #header .box-top .s-nav li {
    padding: 0;
    font-size: 14px;
    font-size: 1.4rem;
    border-bottom: 1px #808080 solid;
  }

  #header .box-top .s-nav li:last-child {
    padding: 0;
  }

  #header .box-top .s-nav li.sp {
    display: block;
  }

  #header .box-top .s-nav li a {
    padding: 8px 15px;
    display: block;
    color: #f2f7d7;
    background-color: #000;
  }

  #header .box-top .s-nav li a:hover {
    opacity: 1;
  }

  #header .box-top .o-nav {
    width: 58px;
    height: 65px;
  }

  #header .box-top .o-nav li {
    width: 58px;
    height: 65px;
    background-position: 0 0;
    background-size: cover;
  }

  #header .box-top .o-nav li a {
    display: block;
    width: 58px;
    height: 65px;
  }

  #header .box-top .o-nav img {
    width: 58px;
    height: 65px;
    visibility: hidden;
  }

  #main {
    position: relative;
  }

  #p-top {
    display: none;
  }

  #p-btm {
    display: block;
  }

  .pankuzu {
    padding: 8px 12px;
    position: static;
    background-color: #e4e5e4;
  }

  .pankuzu .inner {
    max-width: none;
    color: #59595b;
  }

  .pankuzu .inner a {
    color: #59595b;
  }

  /* --------------------
  	footer
  -------------------- */

  #footer {
    padding: 60px 15px 50px;
  }

  #footer .f-box-1 {
    float: right;
    width: 560px;
  }

  #footer .f-box-1 .box {
    letter-spacing: -0.4em;
    width: 400px;
    float: left;
  }

  #footer .f-box-1 .box ul {
    margin: 0;
    padding: 0 20px 0 0;
    list-style: none;
    letter-spacing: normal;
    display: inline-block;
    vertical-align: top;
  }

  #footer .f-box-1 .box ul:nth-child(3n) {
    padding: 0;
  }

  #footer .f-box-1 .box ul li {
    margin: 0;
    padding: 0 0 20px;
    color: #fff;
  }

  #footer .f-box-1 .box ul li a {
    color: #fff;
    text-decoration: none;
  }

  #footer .f-box-1 .box ul li a:hover {
    opacity: 0.6;
  }

  #footer .f-box-1 .box ul li i {
    margin-right: 8px;
  }

  #footer .f-box-1 .box ul.sns {
    padding-top: 10px;
    display: block;
  }

  #footer .f-box-1 .btn-yoyaku {
    float: right;
  }

  #copyright {
    padding: 16px 0;
    border-top: 1px #363f35 solid;
    color: #fff;
    background-color: #040f03;
    font-size: 12px;
    font-size: 1.2rem;
    text-align: center;
  }

  #copyright a {
    color: #fff;
    text-decoration: none;
  }

  #copyright a:hover {
    opacity: 0.6;
  }
}
